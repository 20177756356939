import * as React from "react";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import {
  ArrowUp,
  FacebookWhite,
  InstagramWhite,
  LogoWhite,
  MailYellow,
  TwitterWhite,
  WhatsappYellow,
  YoutubeWhite,
} from "assets";
import { Card } from "./card";
import { Routes } from "routes/utils";

interface FooterProps {
  getStarted: () => void;
}

const Footer: React.FC<FooterProps> = ({ getStarted }) => {
  const date = new Date().getFullYear();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer className={styles["footer-bg"]}>
        <div className={`appContainer ${styles["container"]}`}>
          <Card getStarted={getStarted} />
          <div className={styles["grid"]}>
            <div className={styles["grid-1"]}>
              <LogoWhite className={styles["logo"]} title="medsaf logo" />
              <p className={styles["grid-1-txt"]}>
                MEDSAF is the premiere pharmaceutical technology provider of genuine and authentic medications.
                <br />
                We provide easy access to affordable medications through our technology platform.
              </p>
              <div className={styles["socials"]}>
                <p className={`${styles["socials-txt"]} ${styles["grid-item-hd"]}`}>Socials</p>
                <div className={styles["socials-icons"]}>
                  <a
                    data-testid="facebookLink"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://facebook.com/medsafcom"
                  >
                    <FacebookWhite title="facebook logo" />
                  </a>
                  <a
                    data-testid="twitterLink"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/medsafcom"
                  >
                    <TwitterWhite title="twitter logo" />
                  </a>
                  <a
                    data-testid="instagramLink"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://instagram.com/medsafcom"
                  >
                    <InstagramWhite title="instagram logo" />
                  </a>
                  <a
                    data-testid="youtubeLink"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://m.youtube.com/channel/UC_wYayI3FtdkXOJsdsM-T5g"
                  >
                    <YoutubeWhite title="youtube logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className={styles["grid-2"]}>
              <p className={styles["grid-item-hd"]}>Quick Links</p>
              <ul className={styles["grid-item-list"]}>
                <li>
                  <Link data-testid="" to={Routes.home}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link data-testid="" to={Routes.contactUs}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link data-testid="" to={Routes.aboutUs}>
                    About Us
                  </Link>
                </li>
                {/* <li>
                  <Link data-testid="" to={`${Routes.aboutUs}?faq=true`}>
                    FAQs
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className={styles["grid-3"]}>
              <p className={styles["grid-item-hd"]}>Products</p>
              <ul className={styles["grid-item-list"]}>
                <li>
                  <a rel="noopener noreferrer" target="_blank" href="https://shop.medsaf.com">
                    Marketplace
                  </a>
                </li>
                <li>
                  <a>Patients Direct</a>
                </li>
                <li>
                  <a rel="noopener noreferrer" target="_blank" href="https://assure.medsaf.com">
                    Assure
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href="https://labassure.medsaf.com/" target={"_blank"}>
                    Lab Assure
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href=""
                  >
                    Speedy
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles["grid-4"]}>
              <p className={styles["grid-item-hd"]}>Contact</p>
              <p className={`${styles["grid-3-txt"]} ${styles["grid-3-txt1"]}`}>
                Feel free to get in touch with us via phone or send us a message.
              </p>
              <address>
                <p className={styles["grid-3-txt"]}>55 Sir Tony Eromosele Street, Ikoyi, Lagos, Nigeria</p>
              </address>
              <div className={styles["numbers"]}>
                <WhatsappYellow title="whatsapp icon" />
                <div>
                  <a
                    data-testid="number2"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles["grid-3-link"]}
                    href="https://api.whatsapp.com/send?phone=2348141228328"
                  >
                    +234 814 1228 328
                  </a>
                  <a
                    data-testid="number1"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles["grid-3-link"]}
                    href="https://api.whatsapp.com/send?phone=2348133149983"
                  >
                    +234 813 3149 983
                  </a>
                  <a
                    data-testid="number2"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles["grid-3-link"]}
                    href="https://api.whatsapp.com/send?phone=2349061941939"
                  >
                    +234 906 1941 939
                  </a>
                </div>
              </div>
              <a data-testid="email" className={styles["grid-3-link"]} href="mailto: contact@medsaf.com">
                <MailYellow title="email" />
                contact@medsaf.com
              </a>
            </div>
          </div>
          <hr />
          <div className={styles.footerBottom}>
            <small>© MEDSAF {date}, All Rights Reserved. Made with ❤️</small>

            <ArrowUp onClick={scrollToTop} className={styles.arrowUp} title="arrow up" role="button" />
          </div>
        </div>
      </footer>
    </>
  );
};

export { Footer };
