import * as React from "react";
import styles from "./styles.module.css";

export interface NewsCardProps {
  image: string;
  heading: string;
  text: string;
  Logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
  url: string;
}

const NewsCard: React.FC<NewsCardProps> = ({ image, heading, text, Logo, url }) => {
  const openNews = () => {
    if (url) return window.open(url, "_blank");
  };
  return (
    <div data-aos="slide-up" className={styles.card}>
      <img src={image} alt="" />
      <p onClick={openNews} role="button">
        {heading}
      </p>
      <p>{text}</p>
      {typeof Logo === "string" ? <img className={styles.logo} src={Logo} alt="" /> : <Logo className={styles.logo} />}
    </div>
  );
};

export { NewsCard };
