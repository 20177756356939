import * as React from "react";
import styles from "./styles.module.css";
import { CustomerSection } from "./CustomerSection";
import { HeroSection } from "./HeroSection";
import { ProductSection } from "./ProductSection";
import { ValueSection } from "./ValueSection";
import { NewsSection } from "./NewsSection";

interface HomeProps {
  getStarted: () => void;
}

const HomeUI: React.FC<HomeProps> = ({ getStarted }) => {
  return (
    <React.Fragment>
      <HeroSection getStarted={getStarted} />
      <ProductSection />
      <div className={styles.wrapperBlueBg}>
        <ValueSection />
        <CustomerSection />
      </div>
      <NewsSection />
    </React.Fragment>
  );
};

export { HomeUI };
