import * as React from "react";
import { Error404UI } from "components";
import { useNavigate } from "react-router-dom";

const Error404: React.FC = () => {
  const navigate = useNavigate();
  const home = () => {
    navigate(`/`);
  };
  return <Error404UI home={home} />;
};

export { Error404 };
