import { HomeUI, Survey } from "components";
import * as React from "react";

const Home: React.FC = () => {
  const [showSurvey, setShowSurvey] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Survey show={showSurvey} closeModal={() => setShowSurvey(false)}/>
      <HomeUI getStarted={() => setShowSurvey(true)} />
    </React.Fragment>
  );
};

export { Home };
