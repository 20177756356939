import * as React from "react";
import { useLocation } from "react-router-dom";
import { FAQSection } from "./FAQSection";
import { FounderSection } from "./FounderSection";
import { HeroSection } from "./HeroSection";
import { StatSection } from "./StatSection";

const AboutUsUI = () => {
  const { search } = useLocation();

  const scrollToFAQ = () => {
    const section = document.querySelector("#faq") ?? new Element();
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  React.useEffect(() => {
    if (search === "?faq=true") scrollToFAQ();
  }, [search]);
  return (
    <>
      <HeroSection />
      <StatSection />
      <FounderSection />
      {/* <FAQSection /> */}
    </>
  );
};

export { AboutUsUI };
