import { valuesImage } from "assets";
import * as React from "react";
import styles from "./styles.module.css";

const ValueSection = () => {
  return (
    <section className={`appContainer ${styles.sectionWrapper}`}>
      <div data-aos="fade">
        <h6>Our company values</h6>
        <p>
          There’s a fragmented supply chain and distribution network as well as lack of finances and pharmaceutical
          information structure, and all of these leads to medication changing hand many times between the manufacturers
          and patients. <br />
          Consequences of these over complicated supply chain, leads to raise in costs, decrease in quality and
          reduction in efficiency.<br /> At <span>Medsaf</span> we belive, <span>Quality medication is a fundamental human right</span>.
          We are fighting to bridge that problem across Africa
        </p>
      </div>
      <img data-aos="zoom-out" src={valuesImage} alt="" />
    </section>
  );
};

export { ValueSection };
