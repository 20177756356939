import { BBCLogo, FinancialTimesLogo, GatesFoundationLogo, ReutersLogo, SeedstarsLogo, VanguardLogo } from "assets";
import { Button } from "components";
import * as React from "react";
import styles from "./styles.module.css";

interface HeroProps {
  getStarted: () => void;
}

const HeroSection: React.FC<HeroProps> = ({ getStarted }) => {
  return (
    <section className={`appContainer ${styles.heroContainer}`}>
      <div data-aos="fade-up" className={styles.textSection}>
        <h1>
          Improving Nigeria’s healthcare system with <span>quality</span> and <span>affordable</span> medications
        </h1>

        <p>
          Medsaf is a one-stop-shop for hospitals, clinics, and diagnostic centers to purchase, manage, and track their
          crucial medications needs with technology.
          <br />
          We are a curated medication marketplace for African hospitals and pharmacies.
        </p>
        <Button text="Get Started" onClick={getStarted} type="green" size="large" />
        <p>We are recorgnized by top agencies</p>
        <div className={styles.logoSection}>
          <BBCLogo /> <ReutersLogo /> <VanguardLogo /> <FinancialTimesLogo /> <GatesFoundationLogo /> <SeedstarsLogo />
        </div>
      </div>
      <div data-aos="fade-up" className={styles.imageSection}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
};

export { HeroSection };
