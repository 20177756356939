import * as React from "react";
import styles from "./styles.module.css";

interface StatProps {
  numbers: string;
  text: string;
  id: string;
}

const Stat: React.FC<StatProps> = ({ numbers, text, id }) => {
  return (
    <div className={styles.stat}>
      <p id={id}>{numbers}</p>
      <p>{text}</p>
    </div>
  );
};

const StatSection = () => {
  const [state, setState] = React.useState({
    orders: document.getElementById("orders")?.innerText,
    facilities: document.getElementById("facilities")?.innerText,
    satisfaction: document.getElementById("satisfaction")?.innerText,
    retention: document.getElementById("retention")?.innerText,
  });
  const { orders, facilities, satisfaction, retention } = state;
  let i = 0;
  let j = 0;
  let k = 0;
  let l = 0;

  const increase = () => {
    if (i < 3 || j < 4000 || k < 99.9 || l < 87) {
      if (i < 3) {
        i += 1;
      }
      if (j < 4000) {
        if (j < 500) {
          j += 50;
        } else if (j < 2000) {
          j += 100;
        } else {
          j += 500;
        }
      }

      if (k < 99.9) {
        if (k < 99) {
          k += 3;
        } else {
          k += 0.9;
        }
      }
      if (l < 87) {
        if (l < 85) {
          l += 5;
        } else {
          l += 2;
        }
      }

      setState({
        ...state,
        orders: JSON.stringify(i),
        facilities: JSON.stringify(j),
        satisfaction: k.toFixed(1),
        retention: JSON.stringify(l),
      });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setInterval(increase, 70);
    }, 2600);
  }, []);

  const statList: StatProps[] = [
    {
      numbers: `$${orders ?? "--"}m+`,
      text: "Orders financed",
      id: "orders",
    },
    {
      numbers: `${facilities ?? "--"}+`,
      text: "Facilities onboarded",
      id: "facilities",
    },
    {
      numbers: `${satisfaction ?? "--"}%`,
      text: "Customer satisfaction",
      id: "satisfaction",
    },
    {
      numbers: `${retention ?? "--"}%`,
      text: "Customer retention rate",
      id: "retention",
    },
  ];
  return (
    <section className={`appContainer ${styles.statContainer}`}>
      <div data-aos="fade-up" className={styles.textSection}>
        <p>Resilient and reliable</p>
        <h2>We’re only just getting started on our journey </h2>
        <p>
          We know your request are time sensitive. <br />
          Our team and processess put in place are eligible and well suited to carry out all your medication demands
          with your request being highest in priority and making sure you get your requests in the shortest time
          possible
        </p>
      </div>
      <div className={styles.statSection}>
        {statList.map((item, index) => (
          <Stat {...item} key={index} />
        ))}
      </div>
    </section>
  );
};

export { StatSection };
