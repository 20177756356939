import { Button } from "components/subComponents/Button";
import * as React from "react";
import styles from "./styles.module.css";

interface CardProps {
  getStarted: () => void;
}

const Card: React.FC<CardProps> = ({ getStarted }) => {
  return (
    <div data-aos="zoom-in-up" className={styles.cardWrapper}>
      <h5>Still thinking about it?</h5>
      <p>Join over 4000+ facilities who are actually enjoying our services</p>
      <Button size="large" type="white" text="Get Started" onClick={getStarted} />
    </div>
  );
};
export { Card };
