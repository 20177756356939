import { Preloader, Survey } from "components";
import * as React from "react";
import styles from "./styles.module.css";
import { Footer, Navbar, Testimonials } from "./subComponents";
import AOS from "aos";
import "aos/dist/aos.css";

export interface LayoutProps {
  showTestimonial: boolean;
  active: "home" | "about" | "contact";
  background: "white" | "fauxWhite";
}

const Layout: React.FC<LayoutProps> = ({ showTestimonial, active, background, children }) => {
  const [loading, setLoading] = React.useState(true);
  const [showSurvey, setShowSurvey] = React.useState<boolean>(false);

  React.useEffect((): any => {
    AOS.init({
      duration: 2000,
      offset: 100,
      easing: "ease-in-out",
    });
    AOS.refresh();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <Survey show={showSurvey} closeModal={() => setShowSurvey(false)} />
      {loading ? (
        <Preloader loading={loading} />
      ) : (
        <div className={styles[background]}>
          <Navbar active={active} getStarted={() => setShowSurvey(true)} />
          {children}
          {showTestimonial ? <Testimonials /> : ""}
          <Footer getStarted={() => setShowSurvey(true)} />
        </div>
      )}
    </>
  );
};

export { Layout };
