import gql from "graphql-tag";

// Contact Form

/**
 * Contact Form
 */

export const CONTACT_FORM = gql`
  mutation contactUs($data: ContactUsInputType!) {
    contactUs(data: $data) {
      response {
        success
        message
      }
    }
  }
`;

/***
 * Survey Form
 *
 */
export const SURVEY_FORM = gql`
  mutation survey($data: SurveyInputType!) {
    survey(data: $data) {
      response {
        success
        message
      }
    }
  }
`;
