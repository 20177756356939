import * as React from "react";
import styles from "./styles.module.css";

const CustomerSection = () => {
  return (
    <section className={`appContainer ${styles.sectionWrapper}`}>
      <h6>Hear what our customers have to say</h6>
      <div>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/N2yABbZpFao"
          title="Hear what our customers have to say"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};

export { CustomerSection };
