import * as React from "react";
import styles from "./styles.module.css";

export interface ProductProps {
  reverse: boolean;
  icon: string;
  image: string;
  title: string;
  description: string;
  points: string[];
  url: string;
}

const Product: React.FC<ProductProps> = ({ reverse, icon, image, title, description, points, url }) => {
  return (
    <div className={`${styles.productItem} ${reverse ? styles.reverse : ""}`}>
      <div data-aos={reverse ? "fade-left" : "fade-right"} className={styles.imageSection}>
        <img src={image} alt="" />
      </div>
      <div data-aos="slide-up" className={styles.textSection}>
        <div>
          <img src={icon} alt="title" />
        </div>
        <h5>{title}</h5>
        <p>{description}</p>
        <ul>
          {points.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        {url !== "" ? (
          <a target="_blank" href={url} rel="noreferrer">
            click here to go to {title}
          </a>
        ) : (
          <div className={styles.comingSoon}>Coming soon</div>
        )}
      </div>
    </div>
  );
};

export { Product };
