import {
  assureIcon,
  CaretDown,
  diagnosticsIcon,
  labAssureIcon,
  LogoGreenWithText,
  marketplaceIcon,
  patientsDirectIcon,
  speedyIcon,
} from "assets";
import { Button } from "components";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "routes/utils";
import styles from "./styles.module.css";

interface ProductCardProps {
  icon: string;
  title: string;
  description: string;
  url: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ icon, title, description, url }) => {
  const goToProduct = (url) => {
    if (url !== "/") return window.open(url, "_blank");
  };
  return (
    <div onClick={() => goToProduct(url)} role={url !== "/" ? "button" : "none"} className={styles.productBody}>
      <img src={icon} alt="title" />
      <div className={styles.textWrap}>
        <h6 className={styles.productTitle}>{title}</h6>
        <p className={styles.productDescrip}>{description}</p>
        {url === "/" ? <div className={styles.comingSoon}>Coming soon</div> : ""}
      </div>
    </div>
  );
};

interface NavbarProps {
  active: "home" | "about" | "contact";
  getStarted: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ active, getStarted }) => {
  const [showProducts, setShowProducts] = useState<Boolean>(false);
  const [showNav, setShowNav] = useState<Boolean>(false);
  const [initialState, setInitialState] = useState<Boolean>(true);
  const [background, setBackground] = useState<Boolean>(false);

  const productList: ProductCardProps[] = [
    {
      icon: marketplaceIcon,
      title: "Medsaf Marketplace",
      description: "Your everyday medication needs delivered with tech and care",
      url: "https://shop.medsaf.com",
    },
    {
      icon: assureIcon,
      title: "Medsaf Assure",
      description:
        "Lock in your most important medications at the lowest prices for up to 1 year! Massive cost savings and peace of mind",
      url: "https://assure.medsaf.com",
    },
    {
      icon: patientsDirectIcon,
      title: "Medsaf Patients Direct",
      description:
        "Take the power back and ensure you and your loved ones have access to safe and consistent medications",
      url: "/",
    },
    {
      icon: speedyIcon,
      title: "Medsaf Speedy",
      description:
        "Your crucial medications delivered to your doorstep same day you order. Don't get stranded on medications",
      url: "/",
    },
    {
      icon: labAssureIcon,
      title: "Medsaf Lab Assure",
      description: "High-quality cost-effective consumables sourced worldwide. Covid-19 consumables and more",
      url: "https://labassure.medsaf.com/",
    },
  ];

  const hideProducts = () => {
    setShowProducts(false);
    setInitialState(true);
    setShowNav(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setBackground(true);
    } else {
      setBackground(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  return (
    <React.Fragment>
      <div className={`${styles.fixedNav} ${background ? styles.addBackground : ""}`}>
        <nav className={`appContainer ${styles.navWrapper}`}>
          <Link className={styles.logoLink} to={Routes.home}>
            <LogoGreenWithText className={styles.logo} title="medsaf logo" />
          </Link>
          <button
            title="menu"
            aria-label="menu"
            onClick={() => {
              setShowNav(!showNav);
              setInitialState(true);
            }}
            className={`${styles.hamburger} ${showNav ? styles.displayList : ""}`}
          >
            <div className={styles.line1}></div>
            <div className={styles.line2}></div>
          </button>
          <div className={`${styles.navItemsWrapper} ${!showNav ? styles.mobileHide : ""}`}>
            <Link
              onClick={hideProducts}
              className={`${styles.navItems} ${active === "home" ? styles.navItemsActive : ""}`}
              to={Routes.home}
            >
              Home
            </Link>
            <button
              onClick={() => {
                setShowProducts(!showProducts);
                setInitialState(false);
              }}
              className={`${styles.navItems} ${showProducts ? styles.showProducts : ""}`}
            >
              Products <CaretDown />{" "}
            </button>
            <div
              className={`${styles.productDropdown} ${styles.mobileDropdown} ${
                showProducts ? styles.mobileDropdownShow : !initialState ? styles.mobileDropdownHide : styles.hideNav
              }`}
            >
              <h4 className={styles.productsHeading}>Our Products</h4>
              <div className={styles.productList}>
                {productList.map((item, index) => (
                  <ProductCard {...item} key={index} />
                ))}
              </div>
            </div>

            <Link
              onClick={hideProducts}
              className={`${styles.navItems} ${active === "about" ? styles.navItemsActive : ""}`}
              to={Routes.aboutUs}
            >
              About Us
            </Link>
            <Link
              onClick={hideProducts}
              className={`${styles.navItems} ${active === "contact" ? styles.navItemsActive : ""}`}
              to={Routes.contactUs}
            >
              Contact Us
            </Link>
          </div>
        </nav>
      </div>
      <div className={`${showProducts ? styles.navParent : !initialState ? styles.navParentHide : styles.hideNav}`}>
        <nav className={`appContainer ${styles.navWrapper}`}>
          <Link to={Routes.home}>
            <LogoGreenWithText className={styles.logo} title="medsaf logo" />
          </Link>
          <div className={styles.navItemsWrapper}>
            <Link onClick={hideProducts} className={styles.navItems} to={Routes.home}>
              Home
            </Link>
            <button
              onClick={() => {
                setShowProducts(!showProducts);
                setInitialState(false);
              }}
              className={`${styles.navItems} ${showProducts ? styles.showProducts : ""}`}
            >
              Products <CaretDown />{" "}
            </button>
            <Link onClick={hideProducts} className={styles.navItems} to={Routes.aboutUs}>
              About Us
            </Link>
            <Link onClick={hideProducts} className={styles.navItems} to={Routes.contactUs}>
              Contact Us
            </Link>
          </div>
          <Button
            className={styles.getStartedBtn}
            text="Get Started"
            type="green"
            onClick={() => {
              hideProducts();
              getStarted();
            }}
          />
        </nav>
        <div className={`appContainer ${styles.productDropdown}`}>
          <h4 className={styles.productsHeading}>Our Products</h4>
          <div className={styles.productList}>
            {productList.map((item, index) => (
              <ProductCard {...item} key={index} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export { Navbar };
