import { useMutation } from "react-apollo";
import React from "react";
import { SURVEY_FORM } from "requests/mutations";
import { surveyDetails } from "components";

export const useSurvey = (closeForm: () => void) => {
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: true,
  });

  const [sendSurvey, { loading }] = useMutation(SURVEY_FORM, {
    variables: {
      data: {},
    },
    onCompleted: (data) => {
      closeForm();
      setToast({
        show: true,
        heading: "",
        text: data.survey.response.message,
        type: data.survey.response.success,
      });
      setTimeout(() => {
        setToast({ ...toast, show: false });
      }, 4000);
    },
    onError: (error) => {
      // console.log(error.message);
      setToast({
        show: true,
        heading: error.networkError ? "Network Error" : "",
        text: error.networkError ? "Please check your internet connection and try again." : error.message,
        type: false,
      });
    },
  });

  const sendMessage = (data: surveyDetails) => {
    sendSurvey({
      variables: {
        data,
      },
    });
  };

  const closeSurveyToast = () => {
    setToast({
      ...toast,
      show: false,
    });
  };

  return {
    toast,
    closeSurveyToast,
    loading,
    sendMessage,
  };
};
