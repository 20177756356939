const emailValidator = (value): string => {
  const emailCheck = RegExp(
    /^(([^<>(){}[\].,;:\s@"]+(\.[^<>(){}[\].,;:\s@"]+)*)|(".+"))@(([^<>(){}[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  );

  let message: string;
  if (!emailCheck.test(value)) {
    message = "Please enter a valid email";
  } else {
    message = "";
  }

  return message;
};

export { emailValidator };
