const phoneNumberValidator = (value): string => {
  let message: string;
  if (/[^0-9+]/.test(value)) {
    message = "Phone number should contain only digits";
  } else if (value.length < 11) {
    message = "Enter a valid phone number";
  } else if (value.length === 11 && value[0] !== "0") {
    message = "Enter a valid phone number";
  } else if (value.length > 11 && value.length < 13) {
    message = "Enter a valid phone number";
  } else if (value.length === 13 && value.substring(0, 3) !== "234") {
    message = "Enter a valid phone number";
  } else if (value.length > 13) {
    message = "Detected too many digits. Enter a valid phone number";
  } else {
    message = "";
  }

  return message;
};

export { phoneNumberValidator };
