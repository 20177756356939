import {
  AllAfricaLogo,
  BusinessDayLogo,
  DisruptAfricaLogo,
  news1Image,
  news2Image,
  news3Image,
  news4Image,
  news5Image,
  SeedstarsLogo,
  TechNextLogo,
  TheCableLogo,
} from "assets";
import * as React from "react";
import { NewsCard, NewsCardProps } from "./news";
import styles from "./styles.module.css";

const NewsSection = () => {
  const newsItems: NewsCardProps[] = [
    {
      image: news1Image,
      heading: "Medsaf building future pharmac-eutical procurement for africa",
      text: "What is the value of a pill? Life. Medsaf, a medication marketplace for hospitals and pharmacies, makes sure...",
      Logo: SeedstarsLogo,
      url: 'https://www.seedstars.com/content-hub/life/medsaf-building-future-pharmaceutical-procurement-africa/'
    },
    {
      image: news2Image,
      heading: "Medsaf raising funding as it moves into big data space",
      text: "Nigerian startup Medsaf, which connects pharmaceutical suppliers with hospitals and pharmacies, is raising a seed...",
      Logo: DisruptAfricaLogo,
      url: "https://disrupt-africa.com/2020/12/07/nigerian-pharma-startup-medsaf-raising-funding-as-it-moves-into-big-data-space/"
    },
    {
      image: news3Image,
      heading: "Push out fake meds for Africa's sake - Medsaf launches in Nigeria",
      text: "Med-tech entrepreneur and Medsaf founder Vivian Nwakah spoke with AllAfrica's Sethi Ncube about how she's building...",
      Logo: AllAfricaLogo,
      url: "https://allafrica.com/view/group/main/main/id/00081176.html"
    },
    {
      image: news4Image,
      heading: "Medsaf CEO on malaria death and building million-dollar...",
      text: "When Vivian Nwakah told her family and friends that she was relocating to Nigeria from the United States they tried...",
      Logo: TheCableLogo,
      url: "https://www.thecable.ng/interview-medsaf-ceo-on-a-malaria-death-and-building-million-dollar-health-tech-firm-in-four-years"
    },
    {
      image: news5Image,
      heading: "Vivian Nwakah, CEO of Medsaf",
      text: "Vivian Nwakah is the CEO of Medsaf. Medsaf is a one-stop shop for healthcare stake-holders to purchase, manage...",
      Logo: BusinessDayLogo,
      url: "https://businessday.ng/women-in-business/article/vivian-nwakah-ceo-of-medsaf/"
    },
    {
      image: news2Image,
      heading: "Medsaf is the Healthtech Startup Tackling Counterfeit Drugs in Ni...",
      text: "Fake drugs is a grave global problem and in many developing countries like Nigeria, it is life threatening...",
      Logo: TechNextLogo,
      url: "https://technext.ng/2019/07/04/medsaf-is-the-healthtech-startup-tackling-counterfeit-drugs-in-nigeria/"
    },
  ];
  return (
    <section className={`${styles.sectionWrapper}`}>
      <h6>We are on the News</h6>
      <div>
        {newsItems.map((item, index) => (
          <NewsCard {...item} key={index} />
        ))}
      </div>
    </section>
  );
};

export { NewsSection };
