import { AboutUsUI } from "components";
import * as React from "react";

const AboutUs: React.FC = () => {
  return (
    <React.Fragment>
      <AboutUsUI />
    </React.Fragment>
  );
};

export { AboutUs };
