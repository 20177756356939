import { founderImage, LinkedInWhite, TwitterWhite } from "assets";
import * as React from "react";
import styles from "./styles.module.css";

const FounderSection = () => {
  return (
    <section className={styles.founderBg}>
      <div className={`appContainer ${styles.founderContainer}`}>
        <img src={founderImage} alt="" />
        <div data-aos="slide-left">
          <h3>Meet our CEO & Founder</h3>
          <p>
            Vivian Nwakah is a serial entrepreneur with experience in finance, politics, solar, and health care
            companies.
            <br />
            She attended business school at IAE Sorbonne in paris , Federal University of Rio de Janeiro in Brasil and
            J.Mack Robinson Georgia State. <br />
            She founded MEDSAF in 2016
          </p>
          <div>
            <a href="/">
              <TwitterWhite title="twitter logo" />
            </a>
            <a href="/">
              <LinkedInWhite title="linkedin logo" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export { FounderSection };
