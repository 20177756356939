import {
  assureIcon,
  assureImage,
  diagnosticsIcon,
  diagnosticsImage,
  labAssureIcon,
  labAssureImage,
  marketplaceIcon,
  marketplaceImage,
  patientsDirectIcon,
  patientsDirectImage,
  speedyIcon,
  speedyImage,
} from "assets";
import * as React from "react";
import { Product, ProductProps } from "./product";
import styles from "./styles.module.css";

const ProductSection = () => {
  const products: ProductProps[] = [
    {
      reverse: false,
      icon: marketplaceIcon,
      image: marketplaceImage,
      title: "Medsaf Marketplace",
      description:
        " Medsaf has built a strategic partnerships with the most trusted manufacturer, to provide your facility with quality and affordable medication.",
      points: [
        "Medication mix",
        "Transparency",
        "Effective delivery timeline",
        "Quality assurance / Control as a Service",
        "Competitive price",
      ],
      url: "https://shop.medsaf.com",
    },
    {
      reverse: true,
      icon: assureIcon,
      image: assureImage,
      title: "Medsaf Assure",
      description:
        "Medication prices are always flunctuating in the Nigeiran market due to unstable FX and supply chain issues. Medsaf Assure program allows you to lock in prices of crucial medications and get in front of your medication needs.",
      points: [
        "Locked Medication Prices",
        "Credit Option",
        "Quality Assurance",
        "Stock Management",
        "Doorstep Delivery",
      ],
      url: "https://assure.medsaf.com",
    },
    {
      reverse: false,
      icon: patientsDirectIcon,
      image: patientsDirectImage,
      title: "Medsaf Patients Direct",
      description:
        "Medsaf works with our network of doctors to source the highest quality chronic illness medications for patients. We offer at wholesale prices so that you and your loved ones can afford to have the best care possible.",
      points: [
        "Never run out of critical medication", "Prompt home delivery", "Vetted pharmacist to attend to your needs", "Medication mix"
      ],
      url: "",
    },
    {
      reverse: false,
      icon: speedyIcon,
      image: speedyImage,
      title: "Medsaf Speedy",
      description:
        "Medsaf speedy offers quality and affordable medication delivered to your doorstep the same day you order (if you order before 12pm WAT) Dont getstranded on your crucial medications anymore.",
      points: [
        "Same day delivery (within Lagos), 24-72 hours outside Lagos",
        "Old pricing advantage",
        "Quality assured medication",
        "Medication mix",
      ],
      url: "",
    },
    {
      reverse: true,
      icon: labAssureIcon,
      image: labAssureImage,
      title: "Medsaf Lab Assure",
      description:
        "Medsaf is a provider of quality diagnostics products and services. We provide easy access to quality and affordable diagnostics products, reagents, equipment, and consumables",
      points: ["Credit Options", "Discount on purchases", "Ships across Africa", "Stock Management", "COVID 19 KITS"],
      url: "https://labassure.medsaf.com/",
    },
  ];

  return (
    <section className={`appContainer ${styles.productWrapper}`}>
      <h2 className={styles.title}>Our products</h2>
      <p className={styles.text}>
        We curated a plethora of products to solve ease of movement and access to quality medications across Nigeria
      </p>

      {products.map((item, index) => (
        <Product {...item} key={index} />
      ))}
    </section>
  );
};

export { ProductSection };
