import { ContactUsUI, Preloader, Toast } from "components";
import { ContactProps } from "components/ContactUs/HeroSection";
import * as React from "react";
import { useMutation } from "react-apollo";
import { CONTACT_FORM } from "requests/mutations";

const ContactUs: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [clearForm, setClearForm] = React.useState<boolean>(false);

  const [toast, setToast] = React.useState({
    show: false,
    title: "",
    text: "",
    type: false,
  });

  const [contactUs] = useMutation(CONTACT_FORM, {
    variables: {
      data: {},
    },
    onCompleted: (data) => {
      // console.log(data);
      setLoading(false);
      setClearForm(!clearForm);
      setToast({
        show: true,
        title: "",
        text: data.contactUs.response.message,
        type: data.contactUs.response.success,
      });
    },
    onError: (error) => {
      // console.log(error.message);
      setLoading(false);
      setToast({
        show: true,
        title: error.networkError ? "Network Error" : "",
        text: error.networkError ? "Please check your internet connection and try again." : error.message,
        type: false,
      });
    },
  });

  const submitForm = (data: ContactProps) => {
    // console.log(data);
    setLoading(true);
    return contactUs({
      variables: { data: data },
    });
  };
  return (
    <React.Fragment>
      <Preloader loading={loading} />
      <Toast
        show={toast.show}
        closeModal={() => setToast({ ...toast, show: false })}
        heading={toast.title}
        text={toast.text}
        type={toast.type}
      />
      <ContactUsUI submitForm={(data) => submitForm(data)} clearForm={clearForm} />
    </React.Fragment>
  );
};

export { ContactUs };
