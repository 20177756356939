import styles from "./styles.module.css";
import * as React from "react";
import { Error404, LogoGreenNoText } from "assets";

interface Error404Props {
  home: () => void;
}

const Error404UI: React.FC<Error404Props> = ({ home }) => {
  return (
    <div className={styles.wrapper}>
      <LogoGreenNoText className={styles.logo} title="medsaf logo" />
      <Error404 className={styles.error404} title="error 404" />
      <p className={styles.text}>
        The page you are looking for can not be found
      </p>
      <button onClick={home} className={styles.btn}>
        Go back home
      </button>
    </div>
  );
};

export { Error404UI };
