import { API_URL } from "config";
import React from "react";
import { MainRouter } from "routes";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

const App = () => {
  React.useEffect(() => {
    if (window.location.protocol !== "https:" && process.env.NODE_ENV === "production") {
      let redirect = window.location.href.replace("http", "https");
      // console.log(redirect, " redirect");
      window.location.assign(redirect);
    }
  }, []);

  const client = new ApolloClient({
    uri: API_URL,
    name: "Medsaf Website",
    version: "2.0",
  });

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <MainRouter />
      </ApolloProvider>
    </div>
  );
};

export default App;
