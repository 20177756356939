import { valuesImage } from "assets";
import * as React from "react";
import styles from "./styles.module.css";

const HeroSection = () => {
  return (
    <section className={`appContainer ${styles.heroContainer}`}>
      <div data-aos="fade-up">
        <h1>
          About <span>MEDSAF</span>
        </h1>
        <p>
          Medsaf leverages technology to provide direct access to quality medication manufacturers for hospitals and
          pharmacies. We intend to help African hospitals and pharmacies access credit, inventory management and
          logistics with ease. It’s a one-stop procurement service.
        </p>
      </div>
      <img data-aos="zoom-out" src={valuesImage} alt="" />
    </section>
  );
};

export { HeroSection };
