import { clientImage1, clientImage2, clientImage3 } from "assets";
import * as React from "react";
import styles from "./styles.module.css";
import { TestimonialCard, TestimonialCardProps } from "./testimonial";

const Testimonials = () => {
  const [card, setCard] = React.useState<number>(0);
  const [mobile, setMobile] = React.useState<boolean>(window.innerWidth <= 600 ? true : false);

  const checkWidth = () => {
    if (window.innerWidth <= 600) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  window.onresize = checkWidth;

  const unify = (e) => {
    return e.changedTouches ? e.changedTouches[0] : e;
  };

  let x0 = null;

  const lock = (e) => {
    x0 = unify(e).clientX;
  };

  const move = (e) => {
    if (x0 || x0 === 0) {
      let dx = unify(e).clientX - x0,
        s = Math.sign(dx);
      // if change is significant and is +ve, s = -ve -> card++
      // if change is significant and is -ve, s = +ve -> card--

      if (s < 0) {
        setCard((prev) => (prev < 2 ? prev + 1 : prev));
      } else {
        setCard((prev) => (prev > 0 ? prev - 1 : prev));
      }

      x0 = null;
    }
  };

  const testimonials: TestimonialCardProps[] = [
    {
      image: clientImage1,
      name: "Chief Anil Grover",
      role: "MD - Grover Medical Center",
      text: "MEDSAF has a very robust supply chain unit, Now we are not bothered to check the source of these suppliers or who’s the manufacturer, is it genuine or not, is it original or fake, what is the quality assurance system, where the products are stored. All of these issues we are not concerned now, because MEDSAF takes care of them",
    },
    {
      image: clientImage2,
      name: "Dr. Ifeanyi Obiora",
      role: "COO - Eko Hospital",
      text: "We use to deal with so many suppliers, we had about 40 to 50 different suppliers, that alone was an administrative burden, sending requests to different suppliers, processing payment was an administrative nightmare. since we started working with MEDSAF, i can say that all those challenges went away. With MEDSAF the process is pretty streamlined, with speed and quality of service",
    },
    {
      image: clientImage3,
      name: "Unoma Grant",
      role: "COO  - Paleon Memorial Hospital",
      text: "MEDSAF has made procurement easier, they made price competition better, everybody else takes advantage of you at the market, but when you are buying from MEDSAF you are buying at the lowest possible price everytime, regardless of whether the product is scarce or fluid",
    },
  ];
  return (
    <section data-aos="slide-right" className={`appContainer ${styles.sectionWrapper}`}>
      <h6>Clients testimonials</h6>
      <div onTouchEnd={move} onMouseUp={move} onTouchStart={lock} onMouseDown={lock} className={styles.cardWrapper}>
        {testimonials.map((item, index) => {
          if ((card === index && mobile) || !mobile) {
            return <TestimonialCard {...item} key={index} />;
          }
        })}
      </div>
      <div className={styles.cardControl}>
        <div
          title="testimonial1"
          aria-label="testimonial1"
          onClick={() => setCard(0)}
          role="button"
          className={card === 0 ? styles.activeCard : ""}
        ></div>
        <div
          title="testimonial2"
          aria-label="testimonial2"
          onClick={() => setCard(1)}
          role="button"
          className={card === 1 ? styles.activeCard : ""}
        ></div>
        <div
          title="testimonial3"
          aria-label="testimonial3"
          onClick={() => setCard(2)}
          role="button"
          className={card === 2 ? styles.activeCard : ""}
        ></div>
      </div>
    </section>
  );
};

export { Testimonials };
