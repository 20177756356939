import * as React from "react";
import styles from "./styles.module.css";

interface ButtonProps {
  text: string;
  onClick: () => void;
  type: "green" | "white";
  size?: "small" | "normal" | "large";
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ text, onClick, type, size, className }) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={`${styles.base} ${styles[type]} ${size ? styles[size] : ""} ${className}`}
    >
      {text}
    </button>
  );
};

export { Button };
