import * as React from "react";
import { ContactInfoSection } from "./ContactInfoSection";
import { HeroProps, HeroSection } from "./HeroSection";

const ContactUsUI: React.FC<HeroProps> = ({ submitForm, clearForm }) => {
  return (
    <React.Fragment>
      <HeroSection submitForm={submitForm} clearForm={clearForm} />
      <ContactInfoSection />
    </React.Fragment>
  );
};

export { ContactUsUI };
