import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./styles.module.css";
import { TickIconGreen } from "assets";

interface ToastProps {
  heading: string;
  text: string;
  closeModal: () => void;
  show: boolean;
  type: boolean;
}

const Toast: React.FC<ToastProps> = ({ heading, text, closeModal, show, type }) => {
  return (
    <>
      <Modal
        className={type ? styles["v-toast"] : `${styles["v-toast"]} ${styles["v-toast-red"]}`}
        show={show}
        onHide={closeModal}
        centered
      >
        <Modal.Body>
          {type ? (
            <TickIconGreen className={styles["v-toast-img"]} title="tick" />
          ) : (
            <div className={styles["v-toast-red-icon"]}>&times;</div>
          )}
          <div>
            <h6 className={styles["v-toast-ttl"]}>{heading}</h6>
            <p className={styles["v-toast-txt"]}>{text}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { Toast };
