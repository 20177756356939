import { Button } from "components";
import { emailValidator } from "Helpers";
import * as React from "react";
import styles from "./styles.module.css";

export interface ContactProps {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

export interface HeroProps {
  submitForm: (data: ContactProps) => void;
  clearForm: boolean;
}
const initialState: ContactProps = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
};

const HeroSection: React.FC<HeroProps> = ({ submitForm, clearForm }) => {
  const [contactInfo, setContactInfo] = React.useState<ContactProps>(initialState);
  const [empty, setEmpty] = React.useState<string>("");
  const [emailMessage, setEmailMessage] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");

  React.useEffect(() => {
    setContactInfo(initialState);
  }, [clearForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      let message = emailValidator(value);
      if (message) {
        setEmpty(name);
        setEmailMessage(message);
      } else {
        setEmpty("");
        setEmailMessage("");
      }
    }

    setContactInfo({
      ...contactInfo,
      [name]: value,
    });
  };

  const submit = () => {
    return submitForm(contactInfo);
  };

  const verify = () => {
    if (!contactInfo.email || !contactInfo.firstName || !contactInfo.lastName || !contactInfo.message) {
      setEmpty(
        !contactInfo.firstName
          ? "firstName"
          : !contactInfo.lastName
          ? "lastName"
          : !contactInfo.email
          ? "email"
          : !contactInfo.email
          ? "email"
          : "",
      );
      setMessage("Please complete all fields");
      return;
    }

    setEmpty("");
    setMessage("");
    return submit();
  };
  return (
    <section className={`appContainer ${styles.heroContainer}`}>
      <div className={styles.textSection}>
        <p>Contact Us</p>
        <h1>We’d love to hear from you</h1>
        <p>Our friendly team is always here to chat</p>
      </div>
      <div className={styles.formSection}>
        <h4>Get in touch</h4>
        <p>We’d love to hear from you, please fill the form</p>
        <form className={styles.formContainer}>
          <div className={styles.halfInput}>
            <label>First name </label>
            <input
              className={empty === "firstName" ? styles.empty : ""}
              value={contactInfo.firstName}
              name="firstName"
              onChange={handleChange}
              type="text"
              required
            />
          </div>
          <div className={styles.halfInput}>
            <label>Last name </label>
            <input
              className={empty === "lastName" ? styles.empty : ""}
              value={contactInfo.lastName}
              name="lastName"
              onChange={handleChange}
              type="text"
              required
            />
          </div>
          <div className={styles.fullInput}>
            <label>Email address </label>
            <input
              className={empty === "email" ? styles.empty : ""}
              value={contactInfo.email}
              name="email"
              onChange={handleChange}
              type="email"
              required
            />
            {emailMessage && <p className={styles.inputMessage}>{emailMessage}</p>}
          </div>
          <div className={styles.fullInput}>
            <label>Message </label>
            <textarea
              className={empty === "message" ? styles.empty : ""}
              value={contactInfo.message}
              name="message"
              onChange={handleChange}
              required
            />
          </div>
          <Button className={styles.btn} onClick={verify} text="Send Message" size="normal" type="green" />
          {message && <p className={styles.message}>{message}</p>}
        </form>
      </div>
    </section>
  );
};

export { HeroSection };
