import * as React from "react";
import { Modal } from "react-bootstrap";
import styles from "./styles.module.css";
import { Button, Toast } from "..";
import Select from "react-select";
import { phoneNumberValidator, useSurvey, emailValidator } from "Helpers";
import { Preloader } from "../Preloader";

export interface surveyDetails {
  name: string;
  customerType: string;
  facilityName: string;
  address: string;
  phone: string;
  email: string;
  comment: string;
}

interface SurveyProps {
  closeModal: () => void;
  show: boolean;
}

interface optionType {
  value: string;
  label: string;
}

const initialState: optionType = {
  value: "",
  label: "",
};

const initialFormState:surveyDetails = {
  name: "",
    customerType: "",
    facilityName: "",
    address: "",
    phone: "",
    email: "",
    comment: "",
}

const Survey: React.FC<SurveyProps> = ({ closeModal, show }) => {
  const [product, setProduct] = React.useState<optionType>(initialState);
  const [surveyInfo, setSurveyInfo] = React.useState(initialFormState);
  const [empty, setEmpty] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [phoneMessage, setPhoneMessage] = React.useState<string>("");
  const [emailMessage, setEmailMessage] = React.useState<string>("");

  const options: optionType[] = [
    {
      value: "Marketplace",
      label: "Medsaf Marketplace",
    },
    {
      value: "Assure",
      label: "Medsaf Assure",
    },
    {
      value: "Lab Assure",
      label: "Medsaf Lab Assure",
    },
    {
      value: "Patients Direct",
      label: "Medsaf Patients Direct",
    },
    {
      value: "Speedy",
      label: "Medsaf Speedy",
    },
  ];

  const handleChangeState = (val) => {
    setSurveyInfo({
      ...surveyInfo,
      customerType: val.value,
    });
    setProduct(val);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      let message = phoneNumberValidator(value);
      if (message) {
        setEmpty(name);
        setPhoneMessage(message);
      } else {
        setEmpty("");
        setPhoneMessage("");
      }
      console.log(message);
    }

    if (name === "email") {
      let message = emailValidator(value);
      if (message) {
        setEmpty(name);
        setEmailMessage(message);
      } else {
        setEmpty("");
        setEmailMessage("");
      }
    }

    setSurveyInfo({
      ...surveyInfo,
      [name]: value,
    });
  };

  const { toast, closeSurveyToast, sendMessage, loading } = useSurvey(closeModal);

  const submit = () => {
    sendMessage(surveyInfo);
  };

  const verify = () => {
    if (
      !surveyInfo.address ||
      !surveyInfo.comment ||
      !surveyInfo.customerType ||
      !surveyInfo.email ||
      !surveyInfo.facilityName ||
      !surveyInfo.name ||
      !surveyInfo.phone
    ) {
      setEmpty(
        !surveyInfo.name
          ? "name"
          : !surveyInfo.customerType
          ? "customerType"
          : !surveyInfo.facilityName
          ? "facilityName"
          : !surveyInfo.address
          ? "address"
          : !surveyInfo.phone
          ? "phone"
          : !surveyInfo.email
          ? "email"
          : !surveyInfo.comment
          ? "comment"
          : "",
      );
      setMessage("Please complete all fields");
      window.scrollTo(0, 0);
      return;
    }

    setEmpty("");
    setMessage("");
    return submit();
  };

  const close = () => {
    closeModal();
    setMessage("");
    setEmailMessage("");
    setEmpty("")
    setPhoneMessage("");
    setSurveyInfo(initialFormState)
    setProduct(initialState)
  }

  return (
    <>
      <Preloader loading={loading} />
      <Toast {...toast} closeModal={closeSurveyToast} />
      <Modal className={styles.survey} show={show} onHide={close} centered>
        <Modal.Body className={styles.surveyBody}>
          <h1>Let's get you started</h1>
          <p>Fill this little survey to let us know about what you need</p>
          <form className={styles.formContainer}>
            <input
              className={empty === "name" ? styles.empty : ""}
              name="name"
              onChange={handleInputChange}
              type="text"
              placeholder="Full Name"
              required
            />
            <Select
              options={options}
              className={`${styles.dropdownProducts} ${empty === "customerType" ? styles.empty : ""}`}
              classNamePrefix="surveySelect"
              placeholder="Products"
              isSearchable={true}
              value={product.value ? product : null}
              onChange={(val) => handleChangeState(val)}
            />
            <input
              className={empty === "facilityName" ? styles.empty : ""}
              name="facilityName"
              onChange={handleInputChange}
              type="text"
              placeholder="Facility Name"
              required
            />
            <input
              className={empty === "address" ? styles.empty : ""}
              name="address"
              onChange={handleInputChange}
              type="text"
              placeholder="Address"
              required
            />
            <input
              className={empty === "phone" ? styles.empty : ""}
              name="phone"
              onChange={handleInputChange}
              type="tel"
              placeholder="Phone number"
              required
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{4}"
            />
            {phoneMessage && <p className={styles.inputMessage}>{phoneMessage}</p>}
            <input
              className={empty === "email" ? styles.empty : ""}
              name="email"
              onChange={handleInputChange}
              type="email"
              placeholder="Email address"
              required
            />
            {emailMessage && <p className={styles.inputMessage}>{phoneMessage}</p>}

            <textarea
              className={empty === "comment" ? styles.empty : ""}
              name="comment"
              onChange={handleInputChange}
              placeholder="How can we help you?"
              required
            />
            <Button className={styles.btn} onClick={verify} text="Send Message" size="normal" type="green" />
          </form>
          {message && <p className={styles.message}>{message}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export { Survey };
