import { LocationGreenOutline, MailGreenOutline, PhoneGreenOutline } from "assets";
import * as React from "react";
import styles from "./styles.module.css";

interface CardProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title: string | undefined }>;
  title: string;
  text: string;
  info: JSX.Element;
}

const ContactCard: React.FC<CardProps> = ({ Icon, title, text, info }) => {
  return (
    <div className={styles.cardContainer}>
      <div>
        <Icon title={title} />
      </div>
      <p>{title}</p>
      <p>{text}</p>
      {info}
    </div>
  );
};

const ContactInfoSection = () => {
  const contactInfo: CardProps[] = [
    {
      Icon: MailGreenOutline,
      title: "email",
      text: "Shoot us a mail",
      info: (
        <p>
          <a href="mailto: contact@medsaf.com" rel="noopener noreferrer">
            contact@medsaf.com
          </a>
        </p>
      ),
    },
    {
      Icon: LocationGreenOutline,
      title: "office",
      text: "Come say hello at our office",
      info: <p>55 Sir Tony Eromosele Street, Ikoyi, Lagos, Nigeria</p>,
    },
    {
      Icon: PhoneGreenOutline,
      title: "phone",
      text: "Mon - Fri,  9am - 5pm",
      info: (
        <p>
          <a
            data-testid="number1"
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=2348141228328"
          >
            +234 814 1228 328
          </a>
          <br />
          <a
            data-testid="number2"
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=2348133149983"
          >
            +234 813 3149 983
          </a>{" "}
          <br />
          <a
            data-testid="number3"
            rel="noopener noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=2349061941939"
          >
            +234 906 1941 939
          </a>
        </p>
      ),
    },
  ];
  return (
    <section className={`appContainer ${styles.contactContainer}`}>
      {contactInfo.map((item, index) => (
        <ContactCard {...item} key={index} />
      ))}
    </section>
  );
};

export { ContactInfoSection };
